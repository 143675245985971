import { Component, Input,OnInit} from '@angular/core';

@Component({
  selector: 'app-product-details-carousel',
  templateUrl: './product-details-carousel.component.html',
  styleUrls: ['./product-details-carousel.component.scss']
})
export class ProductDetailsCarouselComponent implements OnInit {
  showProducts:boolean;
   @Input() displayProducts = [];
  testimonialImageUrl = 'https://shop.commonsensewellness.com/wp-content/uploads/2024/07/BrainSenseNEW.webp';
  constructor() { }
  ngOnInit(): void {
    if(this.displayProducts.length <= 1){
      this.showProducts = true;
    }else{
      this.showProducts=true;
    }
  }

}
