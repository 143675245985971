import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-science-ingredients',
  templateUrl: './science-ingredients.component.html',
  styleUrls: ['./science-ingredients.component.scss']
})
export class ScienceIngredientsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
