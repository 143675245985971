import { AfterViewInit, Component, EventEmitter, HostListener, OnInit, Output, ViewChild } from "@angular/core";
import { CartItem } from "src/app/modals/cart-item";
import { CartService } from "../services/cart.service";
import { AppSettings, Settings } from "../services/color-option.service";
import { TranslateService } from "@ngx-translate/core";
import { AccountService } from "../services/account.service";
import { UserService } from "../services/user.service";
import { DSProduct } from "src/app/modals/dsproduct.modal";
import { UserServiceModal } from "src/app/modals/userservice.modal";
import { Router } from "@angular/router";
import { RestApiService } from "../services/restapi.service";
import { MatDialog } from "@angular/material/dialog";
import { ItemsListService } from "../services/itemsList.service";
import { environment } from 'src/environments/environment';
import { CompanyService } from "../services/company.service";
import { ConfigService } from "../services/config.service";
import { ProductService } from "../services/product.service";
import * as _ from "lodash";
import { MatMenuTrigger } from "@angular/material/menu";
import { UtilityService } from "../services/utility.service";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit, AfterViewInit {
  @Output() sildeMobileNavEvent = new EventEmitter<any>();
  @ViewChild('shopMenuTrigger', { static: true }) shopMenuTrigger: MatMenuTrigger;
  public currencies = ["USD", "EUR"];
  public currency: any;
  public flag: any;
  public userService: UserServiceModal;
  userServiceModal: UserServiceModal;
  products: Array<DSProduct>;
  shoppingCartItems: CartItem[] = [];
  public settings: Settings;
  isTop: boolean = true;
  currentRouterPath: any;
  isAllunselect: boolean;
  RegionIDForRequest: any;
  selectedCategory: any = { Category: "all", CategoryId: -1 };
  env: any;
  selectedCategoryId: any;

  constructor(
    private accountService: AccountService,
    private cartService: CartService,
    public appSettings: AppSettings,
    public translate: TranslateService,
    public user: UserService,
    public router: Router,
    public apiService: RestApiService,
    public UserService: UserService,
    public dialog: MatDialog,
    public itemsListService: ItemsListService,
    public companyService: CompanyService,
    private productService: ProductService,
    public configService: ConfigService,
    public utilityService: UtilityService
  ) {
    this.userServiceModal = this.UserService?.userServiceModal;
    this.settings = this.appSettings.settings;
    this.userService = this.user.userServiceModal;
    this.cartService.getItems().subscribe((shoppingCartItems) => (this.shoppingCartItems = shoppingCartItems));
    this.router.events.subscribe((event) => { this.currentRouterPath = router.url; });
    this.env=environment;
  }

  ngOnInit() {
    this.currency = this.currencies[0];
    let a = sessionStorage.getItem('selectedCountry')
    this.configService.getCommonSetting(a, sessionStorage.getItem('SelectedLanguage')).then(() => {
      this.userServiceModal.selectedCountry = a
      this.RegionIDForRequest = this.companyService.getRegionID(a || this.userServiceModal?.selectedCountry || 'US')
      this.getAllProductItems(this.RegionIDForRequest);
    });
  }

  ngAfterViewInit() {
    console.log( this.itemsListService );
    console.log( this.itemsListService.productList );
  }

  toggleMobileMenu($event) {
    this.sildeMobileNavEvent.emit($event);
  }
  changeCurrency(currency) {
    this.currency = currency;
  }
  changeLang(flag) {
    this.flag = flag;
  }
  logout() {
    this.accountService.logout();
  }
  login() {
    window.open('https://office2.commonsenseww.com/#/Login','_blank');
    // this.dialog.open(LoginDialogComponentComponent, {
    //   width: "600px",
    //   autoFocus: false
    // })
  }
  @HostListener("window:scroll", ["$event"])
  onScroll(): void {
    if (window.scrollY > 60) {
      this.isTop = false;
    } else {
      this.isTop = true;
    }
  }
  isLoggedIn() {
    if (
      Object.keys(this.userService.customerData).length &&
      this.userService.customerData.CustomerId &&
      this.user.checkIfUserAuthenticatedOrNot()
    ) {
      return true;
    } else {
      return false;
    }
  }
  backoffice() {
    let windowReference = window.open();
    let request = {
      CustomerID: this.userService.customerData.CustomerId,
      Dest: 'home'
    }
    this.apiService.getCustomerSSOURL(request).subscribe((result) => {
      windowReference.location = result.Data
    })
  }
  openDialogs() {
      this.router.navigate(['/shopnow']);
  }
  onChange(selectedCategory) {
    this.itemsListService.products = this.products.filter(product => product.CategoryId === selectedCategory).sort((a, b) => a.Category.localeCompare(b.Category)).slice(0, 9);
    this.selectedCategoryId = selectedCategory; 
    console.log(this.selectedCategoryId);
  }
  getAllProductItems(RegionID?) {
    this.selectedCategory = { CategoryId: "all" };
    const request = {
      RegionID: RegionID || 1,
      CategoryId: "all"
    };
    //main request for api calling
    this.productService.getProductByCategory(request).subscribe((products) => {
      this.productService.orders = products.map((item) => {
        item.Price = item.Price || (item.Prices && item.Prices[0] && item.Prices[0].Price);
        return item;
      });

      //filling unique products according to category
      const uniqueRequireProduct = ([] = _.uniqBy(products, (x) => x.CategoryId));

      //selected category data filling
      this.itemsListService.selectedCategories = {};
      if (this.selectedCategory.CategoryId == "all") {
        this.itemsListService.selectedCategories["all"] = true;
      }
      //itemlistservice categorylist data filling
      this.itemsListService.categoryList = _.map(uniqueRequireProduct, (object) => { return _.pick(object, ["CategoryId", "Category"]); });

      //empty product array
      this.products = [];

      const product = (this.itemsListService.products = this.productService.orders);

      product.filter((x) => {
        if (x.Category) {
          this.products.push(x);
        }
      });
      //filling products here in the html use to display products
      this.itemsListService.products = this.products;
      this.onChange(this.itemsListService.products[0].CategoryId);
      this.itemsListService.type = "order";

      //displaying according to category
      var category;
      this.itemsListService.categoryList.filter((x) => {
        if (x.CategoryId == this.selectedCategory.CategoryId) {
          category = x.Category;
        }
      });
      if (this.selectedCategory.CategoryId == "all") {
        this.itemsListService.getItemsByCategory("all");
      } else {
        this.itemsListService.getItemsByCategory(category);
      }
    });
    this.env = environment;
  }
  navigateToProduct()
  {
    this.router.navigate(["/shopnow"]);
    this.shopMenuTrigger.closeMenu();
  }
}
